import React, { ReactElement } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import TextContainer from "../components/elements/TextContainer"
import Image, { FixedObject } from "gatsby-image"

interface Props {
  data: {
    afisha300: ImageObject
    afisha500: ImageObject
    kras300: ImageObject
    kras500: ImageObject
    kras2300: ImageObject
    kras2500: ImageObject
    forum300: ImageObject
    forum500: ImageObject
    perm1300: ImageObject
    perm1500: ImageObject
    perm2300: ImageObject
    perm2500: ImageObject
  }
}

interface ImageObject {
  childImageSharp: {
    fixed: FixedObject
  }
}

const AboutPage: React.FC<Props> = ({
  data: {
    afisha300: {
      childImageSharp: { fixed: afisha300 },
    },
    afisha500: {
      childImageSharp: { fixed: afisha500 },
    },
    kras300: {
      childImageSharp: { fixed: kras300 },
    },
    kras500: {
      childImageSharp: { fixed: kras500 },
    },
    kras2300: {
      childImageSharp: { fixed: kras2300 },
    },
    kras2500: {
      childImageSharp: { fixed: kras2500 },
    },
    forum300: {
      childImageSharp: { fixed: forum300 },
    },
    forum500: {
      childImageSharp: { fixed: forum500 },
    },
    perm1300: {
      childImageSharp: { fixed: perm1300 },
    },
    perm1500: {
      childImageSharp: { fixed: perm1500 },
    },
    perm2300: {
      childImageSharp: { fixed: perm2300 },
    },
    perm2500: {
      childImageSharp: { fixed: perm2500 },
    },
  },
}) => {
  return (
    <React.Fragment>
      <h1 className="text-xl md:text-2xl text-black my-6 pt-6 text-center font-bold">
        Добро пожаловать!
      </h1>

      <div className="flex justify-center py-8">
        <img
          className="block w-48 h-48 md:w-64 md:h-64"
          src="/images/bears/2.svg"
          alt=""
        />
      </div>

      <TextContainer className="text-lg leading-relaxed">
        <p>
          Меня зовут Наталья Усачева. Я живу в Красногорске. Представляю
          некоммерческий, авторский арт-проект #другмоймишка_красный, идея
          которого родилась в конце 2018 года благодаря активной офф- и он-лайн
          деятельности бездомного Музея-мастерской "Друг мой мишка". В ходе
          общения с людьми был сделан важный вывод: многие хотели бы реализовать
          себя в творчестве, но в силу разных обстоятельств не решаются сделать
          первый шаг. Так и появилась идея взять на себя смелость и помочь
          сделать первый шаг навстречу личному творчеству. Почувствовать себя
          настоящим художником. Мною был придуман и создан персонаж
          Музея-мастерской «Друг мой мишка» - Красный мишка (с), который готов
          стать главным героем истории любого участника, рассказанной кистью и
          красками на холсте.
        </p>
        <p>
          В феврале 2019 года началась работа по привлечению к участию в проекте
          людей из Красногорска и других городов мира. С первых же дней, проект
          получил огромное признание! За первые полтора месяца мне прислали 59
          картин-историй Красного мишки. При поддержке друзей была подготовлена
          и проведена I выставка "Друг мой мишка: 59 маленьких историй" в
          Молодежном центре г. Красногорск.
        </p>
        <ImageContainer small={afisha300} big={afisha500} />
        <ImageContainer small={kras300} big={kras500} />
        <ImageContainer small={kras2300} big={kras2500} />
        <p className="my-6">
          В июле 2019 года Арт-проект #ДРУГМОЙМИШКА_КРАСНЫЙ был представлен на
          VII Московском областном форуме "Я-гражданин Подмосковья 2019"
        </p>

        <ImageContainer small={forum300} big={forum500} />
        <p>
          Из-за отсутствия собственного помещения, пока мы организуем и проводим
          выставки на «чужих» выставочных площадях, обеспечивая всем
          необходимым. К осени 2019 года нам прислали 90 картин! В сентябре мы
          провели II международную выставку «Друг мой мишка: 90 маленьких
          историй» в Центральном выставочном зале г.Пермь в рамках фестиваля
          «ТеддиЕврАзия»
        </p>

        <ImageContainer small={perm1300} big={perm1500} />
        <ImageContainer small={perm2300} big={perm2500} />
        <p>
          Несмотря на то, что у музея по-прежнему нет собственного помещения,
          наш арт-проект продолжает расти! Мы получаем письма от новых друзей и
          продолжаем отправлять нашего бумажного Красного мишку в разные города
          и страны!
        </p>
        <p>
          Наш герой создаёт вокруг себя доверительное сообщество
          участников-авторов, которые знакомятся с очень личными историями друг
          друга, общаются, сопереживают друг другу и, тем самым, объединяет всех
          нас истинными и важными ценностями.
        </p>

        <p>
          Маленький Красный мишка помогает каждому больше узнать о других людях,
          их жизненном опыте, чувствах и мечтах. О том, что мы только кажемся
          разными, но все мы нуждаемся в одном и том же: любви, самореализации,
          понимании, добрых воспоминаниях и смелых мечтах, поддержке. Проект
          стал той самой «зоной» абсолютного доверия, взаимоуважения и
          честности, которая так необходима всем людям. В этом проекте нет места
          переживаниям и страху. Ведь никто ни с кем не соревнуется. Каждый
          делает это для себя: раскрывает и дарит Миру свой внутренний Мир, свою
          маленькую личную историю.
        </p>
      </TextContainer>
      <TextContainer className="mb-12">
        <Link className="text-blue-600 underline text-2xl" to="/participate">
          Участвуйте!
        </Link>
      </TextContainer>
    </React.Fragment>
  )
}

function ImageContainer({ small, big }): ReactElement {
  return (
    <React.Fragment>
      <div className="flex md:hidden items-center justify-center my-6">
        <Image fixed={small} />
      </div>
      <div className="hidden md:flex items-center justify-center my-6">
        <Image fixed={big} />
      </div>
    </React.Fragment>
  )
}

export default AboutPage

export const query = graphql`
  query {
    afisha300: file(name: { eq: "афиша" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    afisha500: file(name: { eq: "афиша" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kras300: file(name: { eq: "выставка2" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kras500: file(name: { eq: "выставка2" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kras2300: file(name: { eq: "красногорск2" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kras2500: file(name: { eq: "красногорск2" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    forum300: file(name: { eq: "форум1" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    forum500: file(name: { eq: "форум1" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    perm1300: file(name: { eq: "пермь1" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    perm1500: file(name: { eq: "пермь1" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    perm2300: file(name: { eq: "пермь2" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    perm2500: file(name: { eq: "пермь2" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
